<template>
  <main class="main">
    <section class="section">
      <div class="container">
        <div class="page__wrap">
          <div>
            <sidebar-closet></sidebar-closet>
          </div>
          <div class="page__content">
            <div class="tabs__flex">
              <div class="tabs__filters">
                <div style="visibility: hidden;" class="tabs__filters--group">
                  <label class="tabs__filters--label">De</label>
                  <input
                    class="form__input tabs__filters--input"
                    type="date"
                  />
                </div>

                <div style="visibility: hidden;" class="tabs__filters--group">
                  <label class="tabs__filters--label">Até</label>
                  <input
                    class="form__input tabs__filters--input"
                    type="date"
                  />
                </div>
              </div>
              <div style="text-align: right;" class="tabs__search">
                <div class="search__form">
                  <router-link type="button" class="button_bg" to="/armazenar"> Solicitar coleta </router-link>
                </div>
              </div>
            </div>
            <div class="tabs__flex">
              <div class="tabs__search">
                <div class="search__form">
                  <input class="form__input" type="search" placeholder="Pesquisar..." v-model="search" />
                  <button class="button search__button" type="submit">
                    <i class="icon icon-search"></i>
                  </button>
                </div>
              </div>
              <div class="tabs__filters">
                <div style="visibility: hidden;" class="tabs__filters--group">
                  <label class="tabs__filters--label">De</label>
                  <input
                    class="form__input tabs__filters--input"
                    type="date"
                  />
                </div>

                <div style="visibility: hidden;" class="tabs__filters--group">
                  <label class="tabs__filters--label">Até</label>
                  <input
                    class="form__input tabs__filters--input"
                    type="date"
                  />
                </div>
              </div>
            </div>
            <div class="material__list">
              <div v-if="loading">Carregando dados...</div>
              <div v-else-if="!items.length">Nenhum item cadastrado no momento.</div>

              <div v-else class="material__item" v-for="(item, index) in filtered" :key="index">
                <img class="material__item--image" :src="$help.image(item.photo)" alt="" />

                <div class="material__item--content">
                  <h4>
                    {{ item.name }}
                    <span>{{ item.ammount }} {{ item.unity }}</span>
                  </h4>

                  <div class="material__item--footer">
                    <span
                      class="material__item--anchor"
                      href=""
                      title="Solicitar coleta"
                      @click="handleRequestCollection(item)"
                    >
                      <i class="icon icon-request"></i>
                      {{
                        loadingCollection
                          ? 'Solicitando...'
                          : 'Entregar material'
                      }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <modal
        :modal.sync="collectModal"
        @change-modal="e => (collectModal = e)"
        :selectedMaterial.sync="selectedMaterial"
      />
    </section>
  </main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
// import axios from 'axios'
import swal from 'sweetalert'

export default {
  data: () => ({
    loading: false,
    loadingCollection: false,
    collectModal: false,
    selectedMaterial: null,
    search: '',
    filtered: []
  }),
  components: {
    sidebarCloset: () => import('@/components/sidebar-closet'),
    Modal: () => import('@/components/request-collection.vue')
  },
  computed: {
    ...mapGetters('closet', ['items'])
  },
  methods: {
    ...mapActions('closet', ['fetchItems']),
    async handleRequestCollection (item) {
      this.selectedMaterial = { item_id: item.item_id, id: item.id, recyclable: item.recyclable_items, not_recyclable: item.not_recyclable_items }
      this.collectModal = true
    },

    alert (type = 'success', text = 'Verifique todos os campos.') {
      swal({
        title: type === 'success' ? 'Sucesso!' : 'Ooops!',
        icon: type === 'success' ? 'success' : 'error',
        text,
        button: type === 'success' ? null : 'Ok',
        timer: type === 'success' ? 1250 : 3000
      })
    }
  },
  async mounted () {
    this.loading = true
    this.fetchItems(0)
    this.loading = false
  },
  watch: {
    search (val) {
      const list = []
      if (val !== '') {
        this.items.forEach(element => {
          if (element.name.toLowerCase().includes(val.toLowerCase())) {
            list.push(element)
          }
        })
        this.filtered = list
      } else {
        this.filtered = this.items
      }
    },
    items (val) {
      this.filtered = val
    }
  }
}
</script>
